@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");

.dropdown-menu-right.dropdown-menu.dropdown-menu-lg {
  right: 0;
}

.app {
  .rt-track-key__toggle {
    overflow: hidden;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-color: green;
    color: transparent;
    border: 1px #eee solid;
    border-radius: 100%;
    background-size: 57%;
    padding: 10px;
    background-repeat: no-repeat;
    background-position: center;

    &.rt-track-key__toggle--close {
      background-color: red;
    }
  }
}

.d-flex.align-items-center nav {
  display: flex;
}

.ov {
  overflow: visible !important;
}

.oa {
  overflow: auto !important;
}

.rt-timeline {
  overflow: visible;
}

.rt-controls {
  display: none !important;
}

.offcanvas.offcanvas-end {
  z-index: 9999999;
}

.font-size-30 {
  font-size: 30px;
}

.btn-icon.show {
  display: flex;
}

.year-select {
  height: 35px;
  line-height: 100%;
}

.modal-body {
  .rdw-editor-wrapper {
    min-height: 300px;

    .rdw-editor-main {
      padding: 0 20px;
    }
  }
}

.flex-stack {
  flex-direction: column;
}

.investment-card,
.property-card {
  &.active {
    .card {
      background: #f1f1f1;
    }
  }
}

.field-image {
  height: 200px;
  width: 200px;
  margin: 20px 0;
  border: 1px solid #eee;
  padding: 20px;
  border-radius: 10px;
}

.search-form {
  max-width: 50px;
  overflow: hidden;
  margin-left: auto;
  padding: 0 5px;
  width: 50px;
  transition-duration: 200ms;
  transition-delay: 1ms;
  transition:
    position 0.25s,
    left 0.25s,
    top 0.25s,
    width 0.25s,
    height 0.25s,
    max-width 0.25s,
    max-height 0.25s;
  transition-timing-function: ease;

  &.active {
    background: #fff;
    max-width: 300px;
    width: auto;
    transition-duration: 200ms;
    transition-delay: 1ms;
    transition:
      position 0.25s,
      left 0.25s,
      top 0.25s,
      width 0.25s,
      height 0.25s,
      max-width 0.25s,
      max-height 0.25s;
    transition-timing-function: ease;
  }

  .search-inner {
    width: 300px;
    display: flex;
    align-items: center;

    button {
      display: flex;
      align-items: center;
    }

    .input-group-text {
      padding: 0;
    }
  }
}

.doc-name-box {
  img {
    width: 50px;
  }
}

.card {
  overflow: hidden;
}

.oh {
  overflow: hidden !important;
}

.min-h-0 {
  min-height: 0 !important;
}

.bb-none {
  border-bottom: none !important;
}

.column-label {
  font-weight: 500;
}

.field-content-area {
  .justify-content-start {
    justify-content: flex-end !important;
  }

  .text-left {
    text-align: right !important;
  }

  span,
  time {
    font-size: 13px;
  }
}

.attatchment-box {
  position: absolute;
  bottom: 70px;
  left: 9px;
}

.fs-10 time {
  font-size: 10px !important;
}

.fw-medium time {
  font-weight: 500 !important;
}

.text-muted time {
  --bs-text-opacity: 1;
  color: #64748b !important;
}

.f-600 {
  font-weight: 600;
}

.success-text {
  color: #2ecc71;
  font-weight: bold;
}

.property-card-small {
  h5,
  span {
    color: #fff;
  }
}

.error-text {
  color: #e74c3c;
  font-weight: bold;
}

.fh {
  min-height: 100%;
}

.tab-menu {
  top: 145px;
  position: relative;
  z-index: 9999;
}

.nav-tabs-wrapper .nav-tabs-custom-style .nav-item .nav-link {
  border-right: #e3e2e2 1px solid;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  background: #f1f1f1;
}

.relative {
  position: relative;
}

.align-item-center {
  align-items: center;
}

.nxl-container .nxl-content .main-content .content-sidebar {
  height: calc(100vh - 211px);

  &.document-sidebar {
    height: calc(100vh - 146px);
  }
}

.nxl-container .nxl-content .main-content .content-area {
  height: calc(100vh - 215px);
}

.nxl-container.apps-container
  .nxl-content.without-header
  .main-content
  .content-area.form-area,
.nxl-container.apps-container
  .nxl-content.without-header
  .main-content
  .content-sidebar {
  height: calc(100vh - 215px);
}

.nxl-container .nxl-content .main-content .content-sidebar.contact-sidebar,
.nxl-container .nxl-content .main-content .content-area.contact-area {
  height: calc(100vh - 146px);

  .rdt_TableCell {
    flex-wrap: wrap;
  }
}

.nxl-container .nxl-content .main-content .content-area .content-area-body {
  padding: 30px 30px;
  max-height: calc(100% - 75px);
  height: auto;
  overflow: auto;
}

.sticky {
  position: sticky;
}

.select-box__control .select-box__single-value {
  font-size: 12px;
}

.form-group .react-datepicker-wrapper {
  display: block;
}

.page-header {
  position: sticky;
  border-bottom: 1px solid #e5e7eb;
}

.nxl-user-dropdown .dropdown-menu.roles.show {
  display: block;
  left: -160px !important;
  top: 30px !important;
}

.nxl-user-dropdown .dropdown-menu.entities.show {
  display: block;
  left: -275px !important;
  top: -10px !important;
}

.dropdown .dropdown-menu {
  width: auto;
}

.dropdown .dropdown-menu.entities {
  min-width: 275px;
}

.tall {
  .apexcharts-canvas {
    height: 100%;
  }
}

.nxl-header .header-wrapper .nxl-h-dropdown .dropdown-header {
  margin-bottom: 0;
}

.avatar-image {
  color: #fff;
}

.avatar-image {
  .img-fluid {
    max-width: 100%;
    height: auto;
    height: 100%;
    object-fit: cover;
    width: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
    color: #fff;
    position: relative;
  }
}

.apexcharts-canvas {
  width: 100% !important;
}

.login-image {
  object-fit: cover;
  height: 100%;
}

.nxl-navigation .m-header {
  padding: 15px;
}

.table .progress {
  margin-bottom: 0;
  min-width: 50px;
  margin-left: 10px;
}

.chat-content-wrapper {
  .nxl-container {
    margin-left: 0;
    top: 0;
  }

  .main-content {
    padding: 0 !important;
  }
}

.no-sidemargin {
  margin-left: 0 !important;
  top: 0;
}

.app-skin-light {
  .search-form {
    input,
    div,
    span,
    button,
    input.form-control {
      background-color: transparent;
      border: none;
    }

    button {
      color: #283c50;
    }
  }
}

.dropzone .block-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-group {
  text-align: left;
}

.app-skin-light .dropzone-area .dropzone,
.app-skin-light .modal .dropzone {
  height: 370px;
  display: flex;
  border: 2px solid;
  background: rgba(40, 41, 61, 0.08);
  border-color: #eee;
  justify-content: center;
  align-items: center;
}

.dropzone-area .dropzone .main-text,
.modal .dropzone .main-text {
  font-size: 20px;
}

.dropzone-area .dropzone i,
.modal .dropzone i {
  font-size: 64px;
  margin-bottom: 20px;
}

#modalMaster,
.modal-backdrop.show {
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  display: block;
  z-index: 99999;
  width: 100vw;
  height: 100vh;

  &.modal.show .modal-dialog {
    transform: none;
    z-index: 99999;
  }
}

.modal-backdrop.show {
  opacity: 0.5;
  background-color: #000;
}

.modal-backdrop {
  background-color: #022142;
  transition: all 0.2s linear;
}

.nxl-navigation .navbar-content .nxl-micon {
  width: 30px;
}

.reports svg {
  height: 27px;
  position: relative;
  left: -7px;
  width: 30px;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

.nav-main-dark .nav-main-link.active,
.page-header-dark #page-header .nav-main-link.active,
.sidebar-dark #sidebar .nav-main-item.active .nav-main-link {
  color: #fff;

  .nav-main-link-icon {
    color: #fff;
  }
}

.table > :not(caption) > * > * {
  //padding: 0;
}

.nxl-header {
  top: 0;
  z-index: 99999;
}

.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.emojionearea.emojionearea-inline {
  height: 42px;
}

.gap-2 {
  gap: 0.5rem;
}

.small,
small {
  font-size: 0.875em;
}

.ps {
  touch-action: auto;
  overflow-anchor: none;
  overflow: auto;
}

.emoji-picker {
  width: 100%;
}

.emojionearea,
.emojionearea input {
  padding: 0;
  width: 100%;
  height: auto;
  border: none;
  display: block;
  font-size: 13px;
  box-shadow: none;
  border-radius: 3px;
  text-align: left;
  position: relative;
  background-color: transparent;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.border-end {
  border-right: 1px solid #dadfe4;
}

.border-start {
  border-left: 1px solid #dadfe4;
}

.ms-auto {
  margin-left: auto;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

.border-gray-5 {
  border-color: #dadfe4;
}

.app {
  .chat-content-wrapper {
    border-top: 1px solid #dadfe4;
    padding-top: 80px;
    margin-left: 50px;

    .nxl-container {
      position: relative;
      top: 0px;
      margin-left: 0;
      min-height: calc(100vh - 80px);
      transition: all 0.3s ease;
      border-top: 1px #f1f1f1 solid;
    }
  }
}

.mt-auto {
  margin-top: auto;
}

.align-center {
  align-items: center;
}

.slick-slider .slick-list,
.slick-slider .slick-track {
  display: flex;
}

.form-group.high-z {
  position: relative;
}

.float-right {
  .progress-parent {
    padding-right: 0;

    .progress-label {
      float: right;
      text-align: right;
    }
  }
}

.__react_modal_image__modal_container {
  z-index: 9999999;
}

.whole-width {
  .Image {
    img {
      width: 100%;
    }
  }
}

.nowrap {
  white-space: nowrap;
}

.large-body {
  padding: 0 30px;

  p,
  span {
    font-size: 16px;
    line-height: 160%;
    font-family: "Source Sans Pro", sans-serif;
    text-align: left;
    background: transparent;
  }
}

.column-section .box-title {
  padding-left: 0;
}

.description-section * {
  text-align: left !important;
  background-color: transparent !important;
  font-size: inherit !important;
  font-family: inherit !important;
}

.invite-image .Image img#react-modal-image-img {
  width: 100%;
  margin: 0 auto;
  height: auto;
  object-fit: contain;
}

.icon-loading {
  width: 90px;
  height: 50px;
  margin-top: 30px;
  margin-bottom: 30px;

  circle {
    fill: #334155;
  }
}

.app-skin-light,
.app-skin-dark {
  .edit-box.header-select {
    width: 90px;
    padding: 8px 10px 8px 10px;
    font-size: 16px;
    display: flex;
    margin-left: auto;
    margin-top: 0;
    margin-bottom: 0;
    background: rgba(40, 41, 61, 0.08);
    left: -7px;
    position: relative;
  }
}

.editable-column-box .select-box__control .select-box__value-container {
  max-height: 24px;
}

.form-box-checkbox .switches-list .switch-container:last-child label {
  margin-bottom: 0;
  height: 20px;
}

.pw-0 {
  padding-left: 0;
  padding-right: 0;
}

.ph-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.mw-0 {
  margin-left: 0;
  margin-right: 0;
}

.mh-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.no-bg {
  background-color: transparent;
}

.no-max {
  .select-box {
    max-width: 100%;
  }
}

.select-box__indicator-separator {
  display: none;
}

.existing-files {
  margin: 12px 5px 0;
}

.link-button {
  .remove {
    color: red;
    font-size: 12px;
    margin-left: 10px;
  }
}

.investment-docs {
  display: flex;

  a {
    padding: 30px;
    display: flex;
    border: 2px #fff solid;
    border-radius: 9px;
    margin: 0 20px;
    flex-direction: column;

    svg {
      width: 40px;
      height: 30px;
      margin-bottom: 10px;
    }
  }
}

.no-min {
  min-height: 0;
}

.rdt_TableBody {
  // max-height: 330px;
  overflow: visible;
  display: block;
}

.vert-hor-center {
  align-items: center;
  display: flex;
  justify-content: center;

  .grow {
    flex-grow: 1;
  }
}

.input-button {
  padding: 2px 15px;
  border-color: #121725;
  color: #fff;
  background: #1a233a;
  border: 1px solid;
  border-radius: 5px;
  outline: none;
}

.dropdown-menu .dropdown-divider {
  margin: 0;
}

.status {
  &.Committed,
  &.active,
  &.Active {
    .dot {
      background: green;
    }
  }

  &.Viewed {
    .dot {
      background: yellow;
    }
  }
}

.center-content {
  justify-content: center;
}

.tall-textarea textarea {
  min-height: 400px;
}

.invite-response {
  .btn {
    &.active {
      background: #fff;
      color: #000;
    }
  }
}

.label-subtext {
  display: block;
  font-weight: 400;
  font-style: italic;
  font-size: 12px;
}

.moon {
  margin-left: 3px;
}

.inherit {
  flex-direction: inherit;
}

.rdt_TableRow {
  button:disabled,
  button[disabled] {
    opacity: 0;
    pointer-events: none;
  }
}

.select-box.edit-box {
  width: 100%;
  border-color: transparent;
  background: transparent;
  line-height: 25px;
  font-size: 16px;
}

.content-wrapper {
  // min-height: 100vh;
}

.select-investor {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  p {
    font-size: 19px;
    margin-bottom: 20px;
  }
}

.investment-slider {
  padding-bottom: 30px;
}

#modalMaster .main-modal-content.ResetPassword {
  max-width: 520px;
}

.investor-btns {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;

  .ContactSearchBox {
    width: 100%;
    text-align: left;
  }
}

.documents,
.dashboard {
  svg {
    height: 21px;
  }
}

.form-group label {
  margin: 2px 5px;
}

.high-z {
  z-index: 99;
}

.pdf-main-box {
  overflow: auto;
}

.react-pdf__Document {
  width: 100%;

  .slick-slide {
    max-width: 50vw;
  }
}

.highZ {
  z-index: 999;
}

.absolute-confirmation {
  /* position: absolute; */
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  max-width: 100%;
  height: 100%;
  padding-top: 90px;
  height: 400px;
}

.opacity-0 {
  opacity: 0;
}

.chat-icon {
  svg {
    height: 25px;
  }
}

.select-box__value-container * {
  color: #fff;
}

.select-box__multi-value__label,
.select-box__multi-value__remove path {
  color: #000;
}

.css-1wa3eu0-placeholder {
  color: rgb(128, 128, 128);
}

.createEntity-ul {
  padding: 0;

  .fa {
    width: 50px;
    padding: 12px;
  }
}

.create-entity {
  background: transparent;
  color: #dadee1;
  border: none;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  text-align: left;
  outline: none;
  display: flex;
  align-items: center;
  font-size: 16px;
}

.main-sidebar {
  display: flex;
  flex-direction: column;
  z-index: 9999;
}

.sidebar {
  margin-bottom: auto;
}

.stroke {
  g {
    stroke: #112239;
  }

  path {
    //fill:transparent  ;
  }
}

.reports {
  svg {
    height: 27px;
  }
}

.breadcrumb-title {
  display: flex;
  align-items: center;
}

.card .rdt_TableRow,
.nxl-content-sidebar-item,
.add-tag {
  .create-tag {
    padding: 10px;
    input {
      border: 1px #eee solid;
      font-size: 12px;
      line-height: 4px;
      padding: 8px;
      width: 100%;
    }

    button {
      color: #fff !important;
      border-color: #3454d1 !important;
      background-color: #3454d1 !important;
      padding: 6px;
      width: 100%;
    }
    label {
    }
  }
}

.avatar-xsm {
  i {
    margin-left: 5px;
    font-size: 11px;
  }
}

.contact-sidebar {
  .single-item {
    border-right: 3px solid transparent;
    &.active {
      color: #3454d1;
      border-right: 3px solid #3454d1;
      background-color: rgba(52, 84, 209, 0.075);
      transition: all 0.3s ease;
      border-right: 3px solid #3454d1;
    }
  }
}

.offcanvas.offcanvas-end.message {
  max-width: 700px;
  width: 100%;
}

.btn-group > .btn {
  position: relative;
  flex: 1 1 auto;
  color: #3454d1 !important;
  border: none !important;
  background-color: rgba(52, 84, 209, 0.075) !important;
  transition: all 0.3s ease;

  &.active {
    color: #fff !important;
    border: none !important;
    background-color: #3454d1 !important;
  }
}

.main-content .content-sidebar.content-sidebar-xl.contact-sidebar {
  max-width: 320px;
}

.no-bg-btn {
  background: transparent;
  border: none;
  text-decoration: none;
  font-weight: 600;
}

.nxl-container
  .nxl-content
  .main-content
  .content-sidebar
  .content-sidebar-header {
  height: 66px;
}
